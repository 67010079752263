import {RootAppState} from '@app-lib/redux/reducers';
import {
  DateFormatOptions,
  PreferencesState,
  ThemeName,
  UserCameraOptions,
} from './reducer';


export function getPreferences(rootState: RootAppState): PreferencesState {
  return rootState.preferences;
}

export function getDateFormatOptions(rootState: RootAppState): DateFormatOptions {
  return rootState.preferences;
}

export function getUserCameraOptions(rootState: RootAppState): UserCameraOptions {
  return rootState.preferences;
}

export function getTheme(rootState: RootAppState): ThemeName {
  return rootState.preferences.theme;
}
